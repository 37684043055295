import { useEffect, useState } from "react";
import { currentPositionPromise } from "@/utils/currentPosition";

export const withAroundCurrentLocation = (Component, {
    locations = [],
    assertLocations = validate
} = {}) => props => (
    <Component
        {...props}
        withAroundCurrentLocation={useAroundCurrentLocation({
            locations,
            assertLocations
        })}
    />
);

export function useAroundCurrentLocation({
    locations = [],
    assertLocations = validate
} = {}) {
    const [currentPosition, setCurrentPosition] = useState({});

    useEffect(() => {
        currentPositionPromise
            .then(({ data }) => setCurrentPosition(data?.result || {}));
    }, []);

    return assertLocations({
        locations,
        currentPosition
    });
};

function validate({ locations, currentPosition }) {
    if ('city' in currentPosition) {
        return !locations.some(({ location }) => Object.is(location, currentPosition.city));
    }

    return true;
}

export default withAroundCurrentLocation;
