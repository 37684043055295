import { createContext, memo } from "react";
import { MOBILE_THRESHOLD } from "../../enum";

export const getIsMobile = () => window.innerWidth <= MOBILE_THRESHOLD;

const initialSettingsState = {
    nearbyMaxLimit: 4,
    attractionsMaxLimit: 4,
    withAroundCurrentLocation: true,
    withLocationsNearby: true,
    withAttractionsNearby: true,
    withRecentSearches: true,
    withActivityInterests: false,
    viewedCookieName: 'cn-interests-viewed',
    ctaCookieName: 'cn-interests',
    getIsMobile
};

export const SettingsContext = createContext(initialSettingsState);

const Settings = ({ children, ...settingsProps }) => (
    <SettingsContext.Provider
        value={{
            ...initialSettingsState,
            ...settingsProps
        }}
    >
        {children}
    </SettingsContext.Provider>
);

export default memo(Settings);
