import { memo } from "react";
import { constants } from "@/utils/constants";
import {
    DiscountActivationWidget,
    DiscountBanner,
    DiscountNotification
} from "@/components/DiscountBanner";
import DiscountCodeBannerContent from "./DiscountCodeBannerContent";
import DiscountCodeBannerTrigger from "./DiscountCodeBannerTrigger";
import useContent from "./useContent";

const DiscountCodeBanner = props => {
    const content = useContent();

    return (
        <DiscountBanner
            {...props}
            vouchers={constants.discount.vouchers}
            sx={{
                mx: '20px',
                p: '20px',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                maxWidth: 1920,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundImage: `url(${content.images[0]})`,
                justifyContent: 'space-between',
                minHeight: 114,

                '@media (min-width: 600px)': {
                    backgroundImage: `url(${content.images[1]})`,
                    minHeight: 154
                },

                '@media (min-width: 1136px)': {
                    mx: 'initial',
                    px: '57px'
                }
            }}
            slots={{
                content: (
                    <DiscountCodeBannerContent />
                ),
                action: (
                    <DiscountCodeBannerTrigger />
                )
            }}
        >
            <DiscountNotification>
                <DiscountActivationWidget />
            </DiscountNotification>
        </DiscountBanner>
    );
};

export default memo(DiscountCodeBanner);
