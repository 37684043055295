import React, { memo, useMemo, useRef, useState } from "react";

import SimpleBar from "simplebar-react";

import useDeviceDetect from "@/src/utils/customHooks/useDeviceDetect";
import analyticsLabelProvider from "@/static/analyticsLabelProvider";
import { EAType } from "@/static/contants";
import Heading from "../../components/Heading";

export const TAB_TYPE = {
  TOP_DESTINATIONS: "Top destinations",
  TOP_COUNTRIES: "Top countries & regions",
};

const Seo = ({ d }) => {
  const { isMobile } = useDeviceDetect();

  const [activeTab, setActiveTab] = useState(TAB_TYPE.TOP_DESTINATIONS);
  const mobileListRef = useRef(null);

  const limit = 24;

  const data = useMemo(() => {
    if (activeTab === TAB_TYPE.TOP_DESTINATIONS) {
      return d.Recommended.slice(0, limit);
    }

    if (activeTab === TAB_TYPE.TOP_COUNTRIES) {
      return d.TopCountries.slice(0, limit);
    }
  }, [d, activeTab, limit]);

  const handleOptionClick = (tabType) => (e) => {
    setActiveTab(tabType);
    if(isMobile && mobileListRef.current && activeTab !== tabType) {
      mobileListRef.current.getScrollElement().scrollTo({ left: 0, behavior: "smooth" });
    }
  };

  const renderLists = data?.map((el, i) => {
    return (
      <div
        className="cn__hp__seo__listing__option"
        key={i}
        data-cn-ga-click={JSON.stringify({
          el: analyticsLabelProvider.seoLinks.tab.city[EAType.CLICK](
            activeTab,
            el.name
          ),
        })}
      >
        <a
          href={el.url?.startsWith("/") ? el.url : `/${el.url || ""}`}
          target="_self"
        >
          <p className="cn__hp__seo__listing__option__title">{el.name}</p>
          <p className="cn__hp__seo__listing__option__count">
            {el.count} properties
          </p>
        </a>
      </div>
    );
  });

  const renderTabBar = Object.values(TAB_TYPE).map((item, index) => (
    <p
      key={String(index)}
      className={`cn__hp__seo__options__option cnhpseo--3 ${
        item === activeTab && "cnhpseo--active"
      }`}
      onClick={handleOptionClick(item)}
      data-cn-ga-click={JSON.stringify({
        el: analyticsLabelProvider.seoLinks.tab[EAType.CLICK](item),
      })}
    >
      {item}
    </p>
  ));

  return (
    <>
      <section
        test-id="geopageHpSeo"
        className="cn__hp__seo"
        data-cn-ga-observe={JSON.stringify({
          el: analyticsLabelProvider.seoLinks[EAType.SCROLL](),
        })}
      >
        <Heading tit="Featured destinations" align="left" />
        <div test-id="geopageSeoOption" className="cn__hp__seo__options">
          {isMobile ? (
            <SimpleBar>
              <div className="slidermobile__wrapper">
                {renderTabBar}
                <div
                  style={{
                    width: isMobile ? 10 : 20,
                    flexShrink: 0,
                    marginLeft: 10,
                  }}
                />
              </div>
            </SimpleBar>
          ) : (
            renderTabBar
          )}
        </div>
        <div className="cn__hp__seo__listing">
          {isMobile ? (
            <SimpleBar ref={mobileListRef}>
              <div className="slidermobile__wrapper">{renderLists}</div>
            </SimpleBar>
          ) : (
            <>{renderLists}</>
          )}
        </div>
      </section>
    </>
  );
};

export default memo(Seo);
