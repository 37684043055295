import { memo } from "react";
import Card from "@mui/material/Card";

const Bannerable = ({ children, ...restProps }) => (
    <Card
        elevation={0}
        {...restProps}
        sx={{
            borderRadius: '20px',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: 'var(--color-alice-blue)',
            m: 'auto',
            ...restProps.sx,
        }}
    >
        {children}
    </Card>
);

export default memo(Bannerable);
