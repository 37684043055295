import { constants } from "@/utils/constants";
import Cookies from "js-cookie";

export default function useExpiration({
    expiration = {},
    vouchers = constants.discount.vouchers
}) {
    const assertVoucherCookies = () => !vouchers.some(({ name }) => Cookies.get(name))

    const canDisplay = (rule = assertVoucherCookies) => Boolean(
        !Cookies.get(expiration.token) &&
        rule()
    );

    const rememberExpiration = () => {
        if (expiration.token) {
            Cookies.set(
                expiration.token,
                true,
                expiration.settings
            );
        }
    };

    return {
        canDisplay,
        rememberExpiration
    };
};
