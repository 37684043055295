import 'react-spring-bottom-sheet/dist/style.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import '../src/styles/main.scss';
import 'react-phone-input-2/lib/style.css';
import 'simplebar/dist/simplebar.min.css';
import 'styles/main.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/swiper.scss';

import AuthWrapper from '@/components/auth/AuthWrapper';
import Environment from '@/providers/Environment';
import OmnisearchState from '@/containers/homePage/Omnisearch/contexts/OmnisearchState';
import useGoogleMaps from '@/utils/customHooks/useGoogleMaps';
import usePageAnalytics from '@/utils/customHooks/usePageAnalytics';
import React from 'react';
import { Provider } from 'react-redux';

import Notificator from '../src/components/common/Notificator';
import { useStore } from '../src/redux/store';
import { MountResizeListener } from '@/src/utils/MountResizeListener';
import Authentication from '@/providers/Authentication';
import { OnboardingInitializer } from '@/components/OnboardingTooltip/OnboardingInitializer';
import { CurrencyProvider } from '@/components/Currency';
import { MobileMenuProvider } from '@/components/AppHeader/Header';
import { UserLocationDialog } from '@/components/UserLocationDialog';

export default function App({ Component, pageProps, err }) {
  const GoogleMapsScript = useGoogleMaps({ dynamicLibImportRoutesRegex: /^\/(sr)/ });
  const store = useStore(pageProps.initialReduxState);

  usePageAnalytics();

  const getLayout = Component.getLayout || (page => page);

  return (
    <Environment>
      <Provider store={store}>
        <CurrencyProvider>
          <MobileMenuProvider>
            <OmnisearchState>
              <Authentication>
                {GoogleMapsScript}
                <AuthWrapper>
                  <MountResizeListener>
                    <OnboardingInitializer>
                      {getLayout(
                        <Component {...pageProps} err={err} />
                      )}
                    </OnboardingInitializer>
                  </MountResizeListener>
                </AuthWrapper>
                <Notificator
                  displayTillUTC="2021-04-01 18:00:00"
                  message="CuddlyNest will be momentarily down for maintenance on April 1 at 08:00 PM CET"
                  cookieOptions={{
                    expires: .5
                  }}
                  cookieKey="cn-maintenance-2021-04-01"
                />
                <UserLocationDialog />
              </Authentication>
            </OmnisearchState>
          </MobileMenuProvider>
        </CurrencyProvider>
      </Provider>
    </Environment>
  );
}
