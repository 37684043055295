import clsx from 'clsx';
import { constants } from '@/utils/constants';
import Desktop from './Desktop';
import Mobile from './Mobile';
import Settings from './contexts/Settings';
import useLocationsNearby from './contexts/LocationsNearby';
import { getIsMobile } from './contexts/Settings/Settings';
import useInitOmnisearch from './useInitOmnisearch';
import { withAroundCurrentLocation } from './hocs';

const Omnisearch = ({
    className,
    nearbyMaxLimit,
    attractionsMaxLimit,
    withAroundCurrentLocation,
    withLocationsNearby,
    withAttractionsNearby,
    withRecentSearches,
    withActivityInterests,
    onMobileOmnisearchOpen,
    onInit
}) => {
    useLocationsNearby(withLocationsNearby);

    const {
        step,
        destination,
        dates,
        guests
    } = useInitOmnisearch(onInit, {
        withAttractionsNearby
    });

    if (typeof window === 'undefined') {
        return null;
    }

    const Component = getIsMobile() ? Mobile : Desktop;

    return (
        <Settings
            nearbyMaxLimit={nearbyMaxLimit}
            attractionsMaxLimit={attractionsMaxLimit}
            withAroundCurrentLocation={withAroundCurrentLocation}
            withLocationsNearby={withLocationsNearby}
            withAttractionsNearby={withAttractionsNearby}
            withRecentSearches={withRecentSearches}
            withActivityInterests={withActivityInterests}
        >
            <div className={clsx('omnisearch', className)}>
                <Component
                    destination={destination}
                    dates={dates}
                    guests={guests}
                    step={step}
                    onMobileOmnisearchOpen={onMobileOmnisearchOpen}
                />
            </div>
        </Settings>
    );
};

Omnisearch.defaultProps = {
    nearbyMaxLimit: 4,
    attractionsMaxLimit: 4,
    withLocationsNearby: true,
    withAttractionsNearby: true,
    withRecentSearches: true
    // withActivityInterests: true
};

export default withAroundCurrentLocation(Omnisearch, {
    locations: constants.overrideMetaCountLocations.slice(0, 2)
});
