import { memo, useEffect, useState } from "react";
import PhoneInput from 'react-phone-input-2';
import clsx from "clsx";

const PhoneInputComponent = ({
    country,
    className,
    dropdownClass,
    containerClass,
    inputClass,
    buttonClass,
    value,
    haveValue,
    ...restProps
}) => {
    // const [formattedValue, setFormattedValue] = useState(value);
    // useEffect(() => {
    //   const formattedNumber = value.startsWith("0") && ["PH"].includes(country) 
    //     ? value 
    //     : value.replace(/^0+/, "");
    //   setFormattedValue(formattedNumber);
    // }, [value, country]);
    return (
        <div className={clsx('phone-input-component', className)}>
            <PhoneInput
                {...restProps}
                countryCodeEditable={true}
                country={haveValue && country}
                containerClass={clsx(
                    'phone-input__container',
                    containerClass
                )}
                inputClass={clsx(
                    'phone-input__input',
                    inputClass
                )}
                buttonClass={clsx(
                    'phone-input__button',
                    buttonClass
                )}
                dropdownClass={clsx(
                    'phone-input__dropdown ',
                    dropdownClass
                )}
                value={haveValue && value}
                areaCodes={{ kz: [7, 6] }}
            />
        </div>
        )
    };

PhoneInputComponent.defaultProps = {
    placeholder: 'country code+phone number',
    country: null
};

export default memo(PhoneInputComponent);
