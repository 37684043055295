import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';
import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';
import ButtonBase from '@material-ui/core/ButtonBase';
import StepEnum from '../enum';
import Search from '@/components/common/icons/Search';
import { DestinationModel, DestinationPicker } from '../Destination';
import InitialStep from '../InitialStep';
import SearchActionArea from '../SearchActionArea';
import { DateRangeModel, DateRangePickerMobile } from '../DateRange';
import { DestinationInputMobile } from '../Destination';
import { GuestModel, GuestPickerMobile } from '../GuestCount';
import analyticsLabelProvider from '@/static/analyticsLabelProvider';
import { EAType } from '@/static/contants';
import { resetGeoLocation } from '@/redux/actions/globalActions';
import InterestsPickerMobile from '../Interests/InterestsPickerMobile';
import MobileFooter from './MobileFooter';
import useBootstrap from '@/containers/newGeoPages/BootstrapContext';
import { useAttractionsNearby } from '../contexts/AttractionsNearby';

const Mobile = ({
    destination, dates, guests,
    step,
    onMobileOmnisearchOpen
    // isOpen,
    // onOpen,
    // onClose,
}) => {
    const dispatch = useDispatch();

    const { fetchAtractionNearby } = useAttractionsNearby();

    const [isOpen, setOpenState] = useState(false);

    const [destinationState, setDestinationState] = destination;
    const [datesState, setDatesState] = dates;
    const [, setGuestsState] = guests;

    const [stepState, setStepState] = step;

    const [isNextStepControlActive, setNextStepControlState] = useState(false);
    const bootstrapData = useBootstrap();

    const prevDestinationInputValueRef = useRef('');

    const resetModels = useCallback(() => {
        setStepState(StepEnum.INITIAL);
        setDestinationState(new DestinationModel);
        setDatesState(new DateRangeModel);
        setGuestsState(new GuestModel);
    }, [
        setStepState,
        setDestinationState,
        setDatesState,
        setGuestsState
    ]);

    const onOpen = () => {
        setOpenState(true);
        setStepState(StepEnum.INITIAL);

        onMobileOmnisearchOpen?.({
            setDestinationState,
            setNextStepControlState,
            setDatesState,
            setGuestsState,
            setStepState,
            fetchAtractionNearby
        });
        if(bootstrapData?.meta_data) {
            transitionToNextStep()
        }
    };

    const onClose = useCallback(() => {
        resetModels();
        setOpenState(false);
        setStepState(StepEnum.INITIAL);
    }, [
        resetModels,
        setStepState
    ]);

    useEffect(() => () => {
        onClose();
        dispatch(resetGeoLocation());
    }, [
        onClose,
        dispatch,
        resetGeoLocation
    ]);

    // Next button management
    useEffect(() => {
        if (prevDestinationInputValueRef.current &&
            destinationState.inputValue &&
            (prevDestinationInputValueRef.current !== destinationState.inputValue)) {
            setNextStepControlState(false);
        }

        prevDestinationInputValueRef.current = destinationState.inputValue;
    }, [destinationState.inputValue]);

    const transitionToNextStep = () => setStepState(StepEnum.DATES);
    const location = bootstrapData?.meta_data?.city || bootstrapData?.meta_data?.state || bootstrapData?.meta_data?.country;
    // useEffect(() => {
    //     if (isOpen) {
    //         // window.scrollTo({
    //         //     top: 0,
    //         //     left: 0,
    //         //     behavior: 'smooth'
    //         // });
    //         setStepState(StepEnum.INITIAL);
    //     }
    //     // else {
    //     //     document.documentElement.style.overflow = document.body.style.overflow = '';
    //     // }
    //     // return () => {
    //     //     document.documentElement.style.overflow = document.body.style.overflow = '';
    //     // };
    // }, [isOpen]);

    return (
        <>
            <ButtonBase
                className='omnisearch_input-wrapper_mobile_trigger'
                test-id='search-action'
                data-cn-ga-click={JSON.stringify({ el: analyticsLabelProvider.omnisearch[EAType.CLICK]() })}
                onClick={onOpen}
            >
                 {location || 'Where to?'}
                <Search />
            </ButtonBase>

            <ReactModal
                isOpen={isOpen}
                shouldCloseOnEsc
                shouldCloseOnOverlayClick
                closeTimeoutMS={300}
                onRequestClose={onClose}
                ariaHideApp={true}
                id="omnisearch-modal"
                preventScroll={true}
                portalClassName="guest-selector_modal omnisearch_input-wrapper"
                overlayClassName="guest-selector_modal-overlay"
                className="guest-selector_modal-content"
            >
                <div className='omnisearch_input-wrapper_mobile'>
                    <div className="omnisearch_input-wrapper_mobile_input-container">
                        {/* <FloatingInput
                            containerProps={{
                                // gtm analytics
                                'data-cn-ga-click': JSON.stringify({
                                    el: analyticsLabelProvider.omnisearch[EAType.CLICK]()
                                })
                            }}
                            onClick={onOpen}
                            className="omnisearch_input"
                            appendContent={<Search />}
                            placeholder="Where to?"
                            value=""
                            onChange={() => ''}
                        /> */}
                        {isOpen && (
                            <DestinationInputMobile
                                destination={destination}
                                setStep={setStepState}
                                onClose={onClose}
                            />
                        )}
                    </div>
                    {isOpen && (
                        <>
                            <div className="omnisearch_input-wrapper_mobile_action-area">
                                <SimpleBar className="omnisearch_input-wrapper_mobile_action-area_scrollbar" forceVisible="y">
                                    {{
                                        [StepEnum.INITIAL]: (
                                            <InitialStep />
                                        ),
                                        [StepEnum.LOCATION]: (
                                            <DestinationPicker
                                                isMobile
                                                value={destinationState}
                                                onChange={setDestinationState}
                                                setStep={setStepState}
                                            />
                                        )
                                    }[stepState]}
                                </SimpleBar>
                            </div>
                            <DateRangePickerMobile
                                value={dates}
                                step={step}
                                onClose={onClose}
                            />
                            <GuestPickerMobile
                                destinationState={destinationState}
                                datesState={datesState}
                                guests={guests}
                                step={step}
                                onClose={onClose}
                            />
                            <InterestsPickerMobile
                                onClose={onClose}
                            />
                        </>
                    )}
                    {isNextStepControlActive && (
                        <MobileFooter
                            className='omnisearch_input-wrapper_mobile_destination-footer'
                            passiveSlot={<span />}
                            activeSlot={(
                                <SearchActionArea
                                    className="omnisearch_input-wrapper_mobile_screen_destination-picker_next-btn"
                                    isMobile
                                    onClick={transitionToNextStep}
                                >
                                    Next
                                </SearchActionArea>
                            )}
                        />
                    )}
                </div>
            </ReactModal>
        </>
    );
};

export default memo(Mobile);
