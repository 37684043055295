import { getCurrentLoc } from "@/redux/services/global";
import { isServer } from "@/providers/Environment";

const defaultConfig = {
    enableHighAccuracy: true,
    // timeout: 6000,
    timeout: 12000,
    maximumAge: 0,
};

const getUserGeolocation = () => new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, defaultConfig);
})

const getCoordsQuery = ({ coords: { latitude, longitude } }) => (
    new URLSearchParams({
        lat: latitude,
        lon: longitude,
    }).toString()
);

const checkInvariant = () => isServer() || !window.navigator.geolocation;


const getPosition = () => new Promise((resolve, reject) => {
    if (checkInvariant()) {
        resolve(getCurrentLoc());
    }

    const handler = async (evt) => {
        let coords;
        try {
            if (!evt.detail.isAllowed) {
                throw new Error('User declined sharing position');
            }

            const coordsObj = await getUserGeolocation();
            coords = `?${getCoordsQuery(coordsObj)}`;
        } catch (err) {
            console.log(err);
        } finally {
            resolve(getCurrentLoc(coords));
        }
    };

    window.addEventListener('userlocation', handler);
});

export const currentPositionPromise = getPosition();
