import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Slider from "react-slick";
import useDeviceDetect from "@/utils/customHooks/useDeviceDetect";
import Button from "components/Atoms/Button";
import {
  LogoIcon,
  GalleryIcon,
  CancelMiddleIcon,
  ChevronRightIcon,
} from "components/Atoms/Icons";
import NextArrowIcon from "@/components/common/nextArrowIcon";
import PrevArrowIcon from "@/components/common/prevArrowIcon";
import { getResizedImgUrl } from "@/utils/globalFunc";
import { MediaSrcSetEnumOnly } from "@/static/contants";
import { Modal } from "../Modal";
import { Loader } from "../Loader";
import ScenicView from "@/components/common/icons/ScenicView";

const defaultItemMapper = (item, config) => (
  <div className="inner">
    {/* getResizedImgUrl() -> for mobile only */}
    <img src={/*getResizedImgUrl(item.url, "375x236")*/item.url} alt={item.alt} />
    {config.caption !== false && item.caption && (
      <p dangerouslySetInnerHTML={{ __html: item.caption }} />
    )}
  </div>
);

export const CarouselSliderRight = ({ className, style, onClick }) => (
  <div className={className} style={style} onClick={onClick}>
    {/* <ChevronRightIcon /> */}
    <NextArrowIcon />
  </div>
);

export const CarouselSliderLeft = ({ className, style, onClick }) => (
  <div className={className} style={style} onClick={onClick}>
    {/* <ChevronRightIcon /> */}
    <PrevArrowIcon />
  </div>
);

export const CarouselSlider = ({
  className,
  config,
  items,
  onClick = () => null,
  itemMapper = defaultItemMapper,
}) => {
  const setting = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: `carousel-slider ${className || ""}`,
    nextArrow: <NextArrowIcon />, // <CarouselSliderRight />,
    prevArrow: <PrevArrowIcon />, // <CarouselSliderLeft />,
    ...config,
  };

  return (
    <Slider {...setting}>
      {(items || []).map((item, indx) => (
        <div key={indx} className="carousel-slider__item" onClick={onClick}>
          {itemMapper ? itemMapper(item, config) : item}
        </div>
      ))}
    </Slider>
  );
};

export const CarouselGallery = ({
  items,
  config = {},
  className,
  itemMapper = defaultItemMapper,
}) => {
  // TODO clean up this area
  const mainSlider = useRef(null);
  const navSlider = useRef(null);
  const [main_slider, setMainSlider] = useState(null);
  const [nav_slider, setNavSlider] = useState(null);

  /**
   * Events
   */
  useEffect(() => {
    if (mainSlider && mainSlider.current) {
      setMainSlider(mainSlider.current);
    }
    if (navSlider && navSlider.current) {
      setNavSlider(navSlider.current);
    }
  }, [mainSlider, navSlider]);

  return (
    <div className={clsx("carousel-slider--gallery", className)}>
      <div className="carousel-slider__main-slider">
        <Slider
          asNavFor={nav_slider}
          ref={mainSlider}
          dots={false}
          infinite
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          className={`carousel-slider ${className || ""}`}
          nextArrow={<CarouselSliderRight />}
          prevArrow={<CarouselSliderLeft />}
        >
          {(items || []).map((item, indx) => (
            <div key={indx} className="carousel-slider__item">
              {itemMapper ? itemMapper(item, config) : item}
            </div>
          ))}
        </Slider>
      </div>
      <div className="carousel-slider__navigation">
        {items.length > 1 && (
          <Slider
            asNavFor={main_slider}
            ref={navSlider}
            dots={false}
            infinite
            speed={500}
            slidesToShow={4}
            slidesToScroll={1}
            className={`carousel-slider ${className || ""}`}
            // arrows={false}
            nextArrow={<CarouselSliderRight />}
            prevArrow={<CarouselSliderLeft />}
            centerMode
            variableWidth
            adaptiveHeight
            swipeToSlide
            focusOnSelect
          >
            {(items || []).map((item, indx) => (
              <div
                key={indx}
                className="carousel-slider__item"
                style={{ width: 166 }}
              >
                <div className="carousel-slider__item__cover">
                  <img src={item.url} alt={item.alt} />
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export const CarouselGalleryDot = ({
  items,
  config = {},
  className,
  itemMapper = defaultItemMapper,
}) => {
  // TODO clean up this area
  const mainSlider = useRef(null);
  const navSlider = useRef(null);
  const [main_slider, setMainSlider] = useState(null);
  const [nav_slider, setNavSlider] = useState(null);

  /**
   * Events
   */
  useEffect(() => {
    if (mainSlider && mainSlider.current) {
      setMainSlider(mainSlider.current);
    }
    if (navSlider && navSlider.current) {
      setNavSlider(navSlider.current);
    }
  }, [mainSlider, navSlider]);

  return (
    <div
      className={clsx(
        "carousel-slider--gallery carousel-slider--gallery--dots",
        className
      )}
    >
      <div className="carousel-slider__main-slider">
        <Slider
          asNavFor={nav_slider}
          ref={mainSlider}
          dots={false}
          infinite
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          className={`carousel-slider ${className || ""}`}
          nextArrow={<CarouselSliderRight />}
          prevArrow={<CarouselSliderLeft />}
        >
          {(items || []).map((item, indx) => (
            <div key={indx} className="carousel-slider__item">
              <img src={item.url} alt={item.alt} />
              {config.caption !== false && item.caption && (
                <p dangerouslySetInnerHTML={{ __html: item.caption }} />
              )}
            </div>
          ))}
        </Slider>
      </div>
      <div className="carousel-slider__navigation">
        {items.length > 1 && (
          <Slider
            asNavFor={main_slider}
            ref={navSlider}
            dots={false}
            speed={500}
            slidesToShow={4}
            slidesToScroll={1}
            className={`carousel-slider ${className || ""}`}
            arrows={false}
            centerMode
            infinite
            variableWidth
          >
            {(items || []).map((_, indx) => (
              <div
                key={indx}
                className="carousel-slider__item"
                style={{ width: 30 }}
              >
                <div className="dot" />
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export const MasonrySlider = ({
  items,
  limit = 2,
  className,
  onItemClick = () => null,
  onShowAllClick = () => null,
  isGalleryLoading = () => false,
  showAllBtn = false,
}) => {
  const { isDesktop } = useDeviceDetect();

  const getImageSize = useCallback(
    ({ isDesktop, isLarge }) =>
      ({
        true: isLarge ? "660x490" : "330x245",
        false: "375x236",
      }[isDesktop]),
    []
  );

  const renderImage = useCallback(
    ({ key, onItemClick, url, size }) => (
      <li
        key={key}
        style={{
          backgroundImage: `url('${url}')`
          // https://cuddlynest.atlassian.net/browse/FE-2025
          // `url(${
          //   key === 0 ? url : getResizedImgUrl(url, size)
          // })`,
        }}
        onClick={onItemClick}
        role="presentation"
      />
    ),
    []
  );

  return (
    <div
      className={clsx(
        "slider-masonry",
        `slider-masonry--item-${limit}`,
        className,
        {
          "slider-masonry--has-more": true, // (items.length > limit || showAllBtn)
        }
      )}
    >
      <ul>
        {[...items].slice(0, limit).map((item, indx) =>
          renderImage({
            key: indx,
            onItemClick,
            url: item.url,
            size: getImageSize({ isDesktop, isLarge: indx === 0 }),
          })
        )}
      </ul>
      {(items.length > limit || showAllBtn) && (
        <Button
          className="more-image"
          title={<div className="more-image__title">Show all photos</div>}
          icon={ScenicView}
          onClick={onShowAllClick}
          leftIcon
          loading={isGalleryLoading()}
        />
      )}
    </div>
  );
};

export const ModalGallery = ({ items }) => {
  const { isMobile, isTablet } = useDeviceDetect();

  return isMobile || isTablet ? (
    <CarouselGalleryDot items={items} />
  ) : (
    <CarouselGallery items={items} />
  );
};

export const MasonryGalleryWithDetail = ({
  items,
  limit = 2,
  showAllBtn = false,
  modal = {},
  isLoading,
  enabledGallery = true,
  gallery,
  onGallery = () => null,
  onClose = () => null,
  sliderConfig = {},
}) => {
  const { isMobile, isTablet, isDesktop } = useDeviceDetect();
  const [enabled_gallery, setEnableGallery] = useState(false);
  const modal_info = {
    title: "Detail",
    backBtnText: "Back to listing details",
    backBtn: false,
    className: "n-modal--gallery",
    error: null,
    ...modal,
  };
  /**
   * Computed
   */
  const image_limit = useMemo(() => {
    const image_length = items.length;
    if (image_length >= limit) return limit;
    return image_length === 4 ? 3 : image_length;
  }, [limit, items]);

  /**
   * Methods
   */
  const handleClose = () => {
    onClose();
    handleGallery(false);
  };
  const handleGallery = (state) => {
    onGallery(state);
    if (enabledGallery) {
      setEnableGallery(state);
    }
  };

  return (
    <>
      {isMobile ? (
        <CarouselSlider
          items={items}
          config={{
            arrows: false,
            caption: false,
            ...sliderConfig,
          }}
          onClick={() => handleGallery(true)}
          itemMapper={(item, config) => (
            <div className="inner">
              <img src={item.url} alt={item.alt} />
              {config.caption !== false && item.caption && (
                <p>{item.caption}</p>
              )}
            </div>
          )}
        />
      ) : (
        <MasonrySlider
          items={items}
          limit={image_limit}
          showAllBtn={showAllBtn}
          onItemClick={() => handleGallery(true)}
          onShowAllClick={() => handleGallery(true)}
        />
      )}
      {enabledGallery && (
        <Modal
          enabled={enabled_gallery}
          notScrollable
          onClose={handleClose}
          onBackClick={() => handleGallery(false)}
          className={modal_info.className}
          fullScreen={isMobile || isTablet}
          backBtn={isMobile ? null : modal_info.backBtn}
          backBtnText={modal_info.backBtnText}
          closeBtn={isDesktop}
        >
          <Modal.Header>
            <LogoIcon className="n-modal__logo" />
            {modal_info.title}
            <div className="n-modal__close-action" onClick={handleClose}>
              <CancelMiddleIcon />
              Close
            </div>
          </Modal.Header>
          <Modal.Content>
            <Loader enabled={isLoading}>
              <Loader.Loading>
                <Loader.Icon height={200} />
              </Loader.Loading>
              <Loader.Resolved>
                {
                  // TODO: cleanup this code
                  modal_info.error ? (
                    modal_info.error
                  ) : (
                    <ModalGallery items={gallery} />
                  )
                }
              </Loader.Resolved>
            </Loader>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};
