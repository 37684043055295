import { memo } from "react";
import {
    DiscountBannerContext,
    useDiscountVoucher,
    useDiscountBanner
} from "./core";
import { Bannerable } from "./layouts";

const DiscountBanner = ({
    slots,
    children,
    settings,
    vouchers,
    layout: Layout,
    ...restProps
}) => (
    <DiscountBannerContext.Provider
        value={{
            ...useDiscountBanner(),
            ...useDiscountVoucher({ vouchers }),
            ...settings
        }}
    >
        <Layout
            {...restProps}
            slots={slots}
        >
            {slots.content}
            {slots.action}
        </Layout>
        {children}
    </DiscountBannerContext.Provider>
);

DiscountBanner.defaultProps = {
    layout: Bannerable,
    // Facade for additional functionalities
    settings: {
        // Expiration extension
        expiration: {},
        // Display timeout
        display: {
            timeoutMs: 5000
        }
    },
    vouchers: [],
    slots: {
        content: null,
        action: null,
    },
    slotProps: {
        content: {},
        action: {},
    },
};

export default memo(DiscountBanner);
