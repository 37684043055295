import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getItem } from '@/utils/localStorageService';
import { constants } from "@/utils/constants";
import { selectCurrency } from "@/redux/actions/globalActions";

export default function useCurrency() {
    const dispatch = useDispatch();

    const [isOpen, setOpen] = useState(false);

    const open = () => setOpen(true);

    const close = () => setOpen(false);

    useEffect(() => {
        const handleCurrencyChange = () =>
            dispatch(
                selectCurrency(
                    getItem('currency', {
                        defaultValue: constants.defaultCurrency
                    })
                )
            );

        document.addEventListener('visibilitychange', handleCurrencyChange);

        return () => {
            document.removeEventListener('visibilitychange', handleCurrencyChange);
        };
    }, []);

    return {
        isOpen,
        setOpen,
        open,
        close
    };
};
